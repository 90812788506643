@import 'uikit/uikit-mixins.scss';

.modalCustom {
  :global {
    .ant-modal-content {
      @include p-6();
    }

    .ant-modal-confirm-body {
      @include d-block();
    }

    .ant-modal-confirm-btns {
      @include d-none();
    }

    .ant-modal-confirm-content {
      @include mt-0();
    }
  }
}

@import 'uikit/uikit-mixins.scss';

.tabsOverview {
  :global {
    .ant-tabs-nav {
      @include mb-0();

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          @include px-6();
          @include text-transform-uppercase();

          .ant-tabs-tab-btn {
            @include text-base-medium();
          }
        }
      }

      &::before {
        // overwrite master page "OTable"
        border: none !important;
      }
    }
  }
}

@import 'uikit/uikit-mixins.scss';

.adminLoginPage {
  height: 100vh;
  background-color: #f8f8f8;
  background-image: url('../../../public/images/loginBackground.svg');
  background-repeat: no-repeat;
  background-position: 50vw;

  .formLogin {
    input {
      width: 400px;
    }
  }

  .content {
    @include m-auto();
    margin-left: 8% !important;
  }

  .wrapFormLogin {
    width: 560px;
    @include p-6();
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 #e2eae8;
  }
}

@import 'uikit/uikit-mixins.scss';

.detailPageCard {
  @include mb-6();

  :global {
    .ant-card-body {
      @include pb-0();
    }
  }
}

@mixin bg-blue() {
  background-color: #2593fc !important;
}
@mixin bg-blue-1() {
  background-color: #1890ff !important;
}
@mixin bg-blue-2() {
  background-color: #b0deff !important;
}
@mixin bg-blue-3() {
  background-color: #2dccff !important;
}
@mixin bg-teal() {
  background-color: #00bdb1 !important;
}
@mixin bg-teal-1() {
  background-color: #a0d9d5 !important;
}
@mixin bg-teal-2() {
  background-color: #33afa6 !important;
}
@mixin bg-teal-3() {
  background-color: #1d7b77 !important;
}
@mixin bg-teal-400() {
  background-color: #2aaeb7 !important;
}
@mixin bg-teal-5() {
  background-color: #6cc8bf !important;
}
@mixin bg-success() {
  background-color: #33b3aa !important;
}
@mixin bg-red() {
  background-color: #ff555b !important;
}
@mixin bg-red-1() {
  background-color: #ff3838 !important;
}
@mixin bg-red-2() {
  background-color: #f44336 !important;
}
@mixin bg-required() {
  background-color: #ff4d4f !important;
}
@mixin bg-purple() {
  background-color: #713dff !important;
}
@mixin bg-purple-1() {
  background-color: #7b61ff !important;
}
@mixin bg-white() {
  background-color: #fff !important;
}
@mixin bg-gray() {
  background-color: #9ea7ad !important;
}
@mixin bg-gray-1() {
  background-color: #f5f5f5 !important;
}
@mixin bg-gray-2() {
  background-color: #d9d9d9 !important;
}
@mixin bg-gray-3() {
  background-color: #e3e4e7 !important;
}
@mixin bg-gray-100() {
  background-color: #f1f2f5 !important;
}
@mixin bg-gray-200() {
  background-color: #ebebeb !important;
}
@mixin bg-gray-300() {
  background-color: #d8d8d8 !important;
}
@mixin bg-gray-400() {
  background-color: #b2b2b2 !important;
}
@mixin bg-gray-500() {
  background-color: #8d8d8f !important;
}
@mixin bg-gray-600() {
  background-color: #808080 !important;
}
@mixin bg-gray-700() {
  background-color: #3e3e3e !important;
}
@mixin bg-gray-800() {
  background-color: #2a2a2a !important;
}
@mixin bg-gray-900() {
  background-color: #001529 !important;
}
@mixin bg-black() {
  background-color: #000 !important;
}
@mixin bg-black-2() {
  background-color: #595959 !important;
}
@mixin bg-orange-1() {
  background-color: #f2772e !important;
}
@mixin bg-orange-2() {
  background-color: #ff7c7c !important;
}
@mixin bg-orange-3() {
  background-color: #ffb302 !important;
}
@mixin bg-orange-4() {
  background-color: #ff904d !important;
}
@mixin bg-yellow() {
  background-color: #f3b130 !important;
}
@mixin bg-yellow-1() {
  background-color: #fdd133 !important;
}
@mixin bg-yellow-2() {
  background-color: #fce83a !important;
}
@mixin bg-navy-1() {
  background-color: #607eaa !important;
}
@mixin bg-pink-1() {
  background-color: #ffc4c4 !important;
}
@mixin bg-green() {
  background-color: #56f000 !important;
}
@mixin bg-green-1() {
  background-color: #52c41a !important;
}
@mixin bg-primary() {
  background-color: #2593fc !important;
}
@mixin text-blue() {
  color: #2593fc !important;
}
@mixin text-blue-1() {
  color: #1890ff !important;
}
@mixin text-blue-2() {
  color: #b0deff !important;
}
@mixin text-blue-3() {
  color: #2dccff !important;
}
@mixin text-teal() {
  color: #00bdb1 !important;
}
@mixin text-teal-1() {
  color: #a0d9d5 !important;
}
@mixin text-teal-2() {
  color: #33afa6 !important;
}
@mixin text-teal-3() {
  color: #1d7b77 !important;
}
@mixin text-teal-400() {
  color: #2aaeb7 !important;
}
@mixin text-teal-5() {
  color: #6cc8bf !important;
}
@mixin text-success() {
  color: #33b3aa !important;
}
@mixin text-red() {
  color: #ff555b !important;
}
@mixin text-red-1() {
  color: #ff3838 !important;
}
@mixin text-red-2() {
  color: #f44336 !important;
}
@mixin text-required() {
  color: #ff4d4f !important;
}
@mixin text-purple() {
  color: #713dff !important;
}
@mixin text-purple-1() {
  color: #7b61ff !important;
}
@mixin text-white() {
  color: #fff !important;
}
@mixin text-gray() {
  color: #9ea7ad !important;
}
@mixin text-gray-1() {
  color: #f5f5f5 !important;
}
@mixin text-gray-2() {
  color: #d9d9d9 !important;
}
@mixin text-gray-3() {
  color: #e3e4e7 !important;
}
@mixin text-gray-100() {
  color: #f1f2f5 !important;
}
@mixin text-gray-200() {
  color: #ebebeb !important;
}
@mixin text-gray-300() {
  color: #d8d8d8 !important;
}
@mixin text-gray-400() {
  color: #b2b2b2 !important;
}
@mixin text-gray-500() {
  color: #8d8d8f !important;
}
@mixin text-gray-600() {
  color: #808080 !important;
}
@mixin text-gray-700() {
  color: #3e3e3e !important;
}
@mixin text-gray-800() {
  color: #2a2a2a !important;
}
@mixin text-gray-900() {
  color: #001529 !important;
}
@mixin text-black() {
  color: #000 !important;
}
@mixin text-black-2() {
  color: #595959 !important;
}
@mixin text-orange-1() {
  color: #f2772e !important;
}
@mixin text-orange-2() {
  color: #ff7c7c !important;
}
@mixin text-orange-3() {
  color: #ffb302 !important;
}
@mixin text-orange-4() {
  color: #ff904d !important;
}
@mixin text-yellow() {
  color: #f3b130 !important;
}
@mixin text-yellow-1() {
  color: #fdd133 !important;
}
@mixin text-yellow-2() {
  color: #fce83a !important;
}
@mixin text-navy-1() {
  color: #607eaa !important;
}
@mixin text-pink-1() {
  color: #ffc4c4 !important;
}
@mixin text-green() {
  color: #56f000 !important;
}
@mixin text-green-1() {
  color: #52c41a !important;
}
@mixin text-primary() {
  color: #2593fc !important;
}
@mixin p-0() {
  padding: 0 !important;
}
@mixin p-1() {
  padding: 0.25rem !important;
}
@mixin p-2() {
  padding: 0.5rem !important;
}
@mixin p-3() {
  padding: 0.75rem !important;
}
@mixin p-4() {
  padding: 1rem !important;
}
@mixin p-5() {
  padding: 1.25rem !important;
}
@mixin p-6() {
  padding: 1.5rem !important;
}
@mixin p-8() {
  padding: 2rem !important;
}
@mixin p-auto() {
  padding: auto !important;
}
@mixin pt-0() {
  padding-top: 0 !important;
}
@mixin pt-1() {
  padding-top: 0.25rem !important;
}
@mixin pt-2() {
  padding-top: 0.5rem !important;
}
@mixin pt-3() {
  padding-top: 0.75rem !important;
}
@mixin pt-4() {
  padding-top: 1rem !important;
}
@mixin pt-5() {
  padding-top: 1.25rem !important;
}
@mixin pt-6() {
  padding-top: 1.5rem !important;
}
@mixin pt-8() {
  padding-top: 2rem !important;
}
@mixin pt-auto() {
  padding-top: auto !important;
}
@mixin pb-0() {
  padding-bottom: 0 !important;
}
@mixin pb-1() {
  padding-bottom: 0.25rem !important;
}
@mixin pb-2() {
  padding-bottom: 0.5rem !important;
}
@mixin pb-3() {
  padding-bottom: 0.75rem !important;
}
@mixin pb-4() {
  padding-bottom: 1rem !important;
}
@mixin pb-5() {
  padding-bottom: 1.25rem !important;
}
@mixin pb-6() {
  padding-bottom: 1.5rem !important;
}
@mixin pb-8() {
  padding-bottom: 2rem !important;
}
@mixin pb-auto() {
  padding-bottom: auto !important;
}
@mixin pl-0() {
  padding-left: 0 !important;
}
@mixin pl-1() {
  padding-left: 0.25rem !important;
}
@mixin pl-2() {
  padding-left: 0.5rem !important;
}
@mixin pl-3() {
  padding-left: 0.75rem !important;
}
@mixin pl-4() {
  padding-left: 1rem !important;
}
@mixin pl-5() {
  padding-left: 1.25rem !important;
}
@mixin pl-6() {
  padding-left: 1.5rem !important;
}
@mixin pl-8() {
  padding-left: 2rem !important;
}
@mixin pl-auto() {
  padding-left: auto !important;
}
@mixin pr-0() {
  padding-right: 0 !important;
}
@mixin pr-1() {
  padding-right: 0.25rem !important;
}
@mixin pr-2() {
  padding-right: 0.5rem !important;
}
@mixin pr-3() {
  padding-right: 0.75rem !important;
}
@mixin pr-4() {
  padding-right: 1rem !important;
}
@mixin pr-5() {
  padding-right: 1.25rem !important;
}
@mixin pr-6() {
  padding-right: 1.5rem !important;
}
@mixin pr-8() {
  padding-right: 2rem !important;
}
@mixin pr-auto() {
  padding-right: auto !important;
}
@mixin m-0() {
  margin: 0 !important;
}
@mixin m-1() {
  margin: 0.25rem !important;
}
@mixin m-2() {
  margin: 0.5rem !important;
}
@mixin m-3() {
  margin: 0.75rem !important;
}
@mixin m-4() {
  margin: 1rem !important;
}
@mixin m-5() {
  margin: 1.25rem !important;
}
@mixin m-6() {
  margin: 1.5rem !important;
}
@mixin m-8() {
  margin: 2rem !important;
}
@mixin m-auto() {
  margin: auto !important;
}
@mixin mt-0() {
  margin-top: 0 !important;
}
@mixin mt-1() {
  margin-top: 0.25rem !important;
}
@mixin mt-2() {
  margin-top: 0.5rem !important;
}
@mixin mt-3() {
  margin-top: 0.75rem !important;
}
@mixin mt-4() {
  margin-top: 1rem !important;
}
@mixin mt-5() {
  margin-top: 1.25rem !important;
}
@mixin mt-6() {
  margin-top: 1.5rem !important;
}
@mixin mt-8() {
  margin-top: 2rem !important;
}
@mixin mt-auto() {
  margin-top: auto !important;
}
@mixin mb-0() {
  margin-bottom: 0 !important;
}
@mixin mb-1() {
  margin-bottom: 0.25rem !important;
}
@mixin mb-2() {
  margin-bottom: 0.5rem !important;
}
@mixin mb-3() {
  margin-bottom: 0.75rem !important;
}
@mixin mb-4() {
  margin-bottom: 1rem !important;
}
@mixin mb-5() {
  margin-bottom: 1.25rem !important;
}
@mixin mb-6() {
  margin-bottom: 1.5rem !important;
}
@mixin mb-8() {
  margin-bottom: 2rem !important;
}
@mixin mb-auto() {
  margin-bottom: auto !important;
}
@mixin ml-0() {
  margin-left: 0 !important;
}
@mixin ml-1() {
  margin-left: 0.25rem !important;
}
@mixin ml-2() {
  margin-left: 0.5rem !important;
}
@mixin ml-3() {
  margin-left: 0.75rem !important;
}
@mixin ml-4() {
  margin-left: 1rem !important;
}
@mixin ml-5() {
  margin-left: 1.25rem !important;
}
@mixin ml-6() {
  margin-left: 1.5rem !important;
}
@mixin ml-8() {
  margin-left: 2rem !important;
}
@mixin ml-auto() {
  margin-left: auto !important;
}
@mixin mr-0() {
  margin-right: 0 !important;
}
@mixin mr-1() {
  margin-right: 0.25rem !important;
}
@mixin mr-2() {
  margin-right: 0.5rem !important;
}
@mixin mr-3() {
  margin-right: 0.75rem !important;
}
@mixin mr-4() {
  margin-right: 1rem !important;
}
@mixin mr-5() {
  margin-right: 1.25rem !important;
}
@mixin mr-6() {
  margin-right: 1.5rem !important;
}
@mixin mr-8() {
  margin-right: 2rem !important;
}
@mixin mr-auto() {
  margin-right: auto !important;
}
@mixin px-0() {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@mixin px-1() {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
@mixin px-2() {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
@mixin px-3() {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
@mixin px-4() {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
@mixin px-5() {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}
@mixin px-6() {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
@mixin px-8() {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
}
@mixin px-auto() {
  padding-right: auto !important;
  padding-left: auto !important;
}
@mixin py-0() {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@mixin py-1() {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
@mixin py-2() {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
@mixin py-3() {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
@mixin py-4() {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
@mixin py-5() {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
@mixin py-6() {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
@mixin py-8() {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
@mixin py-auto() {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
@mixin mx-0() {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@mixin mx-1() {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
@mixin mx-2() {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
@mixin mx-3() {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}
@mixin mx-4() {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
@mixin mx-5() {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}
@mixin mx-6() {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
@mixin mx-8() {
  margin-right: 2rem !important;
  margin-left: 2rem !important;
}
@mixin mx-auto() {
  margin-right: auto !important;
  margin-left: auto !important;
}
@mixin my-0() {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@mixin my-1() {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
@mixin my-2() {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
@mixin my-3() {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
@mixin my-4() {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
@mixin my-5() {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
@mixin my-6() {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
@mixin my-8() {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
@mixin my-auto() {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
@mixin n-p-0() {
  padding: 0 !important;
}
@mixin n-p-1() {
  padding: -0.25rem !important;
}
@mixin n-p-2() {
  padding: -0.5rem !important;
}
@mixin n-p-3() {
  padding: -0.75rem !important;
}
@mixin n-p-4() {
  padding: -1rem !important;
}
@mixin n-p-5() {
  padding: -1.25rem !important;
}
@mixin n-p-6() {
  padding: -1.5rem !important;
}
@mixin n-p-8() {
  padding: -2rem !important;
}
@mixin n-p-auto() {
  padding: auto !important;
}
@mixin n-pt-0() {
  padding-top: 0 !important;
}
@mixin n-pt-1() {
  padding-top: -0.25rem !important;
}
@mixin n-pt-2() {
  padding-top: -0.5rem !important;
}
@mixin n-pt-3() {
  padding-top: -0.75rem !important;
}
@mixin n-pt-4() {
  padding-top: -1rem !important;
}
@mixin n-pt-5() {
  padding-top: -1.25rem !important;
}
@mixin n-pt-6() {
  padding-top: -1.5rem !important;
}
@mixin n-pt-8() {
  padding-top: -2rem !important;
}
@mixin n-pt-auto() {
  padding-top: auto !important;
}
@mixin n-pb-0() {
  padding-bottom: 0 !important;
}
@mixin n-pb-1() {
  padding-bottom: -0.25rem !important;
}
@mixin n-pb-2() {
  padding-bottom: -0.5rem !important;
}
@mixin n-pb-3() {
  padding-bottom: -0.75rem !important;
}
@mixin n-pb-4() {
  padding-bottom: -1rem !important;
}
@mixin n-pb-5() {
  padding-bottom: -1.25rem !important;
}
@mixin n-pb-6() {
  padding-bottom: -1.5rem !important;
}
@mixin n-pb-8() {
  padding-bottom: -2rem !important;
}
@mixin n-pb-auto() {
  padding-bottom: auto !important;
}
@mixin n-pl-0() {
  padding-left: 0 !important;
}
@mixin n-pl-1() {
  padding-left: -0.25rem !important;
}
@mixin n-pl-2() {
  padding-left: -0.5rem !important;
}
@mixin n-pl-3() {
  padding-left: -0.75rem !important;
}
@mixin n-pl-4() {
  padding-left: -1rem !important;
}
@mixin n-pl-5() {
  padding-left: -1.25rem !important;
}
@mixin n-pl-6() {
  padding-left: -1.5rem !important;
}
@mixin n-pl-8() {
  padding-left: -2rem !important;
}
@mixin n-pl-auto() {
  padding-left: auto !important;
}
@mixin n-pr-0() {
  padding-right: 0 !important;
}
@mixin n-pr-1() {
  padding-right: -0.25rem !important;
}
@mixin n-pr-2() {
  padding-right: -0.5rem !important;
}
@mixin n-pr-3() {
  padding-right: -0.75rem !important;
}
@mixin n-pr-4() {
  padding-right: -1rem !important;
}
@mixin n-pr-5() {
  padding-right: -1.25rem !important;
}
@mixin n-pr-6() {
  padding-right: -1.5rem !important;
}
@mixin n-pr-8() {
  padding-right: -2rem !important;
}
@mixin n-pr-auto() {
  padding-right: auto !important;
}
@mixin n-m-0() {
  margin: 0 !important;
}
@mixin n-m-1() {
  margin: -0.25rem !important;
}
@mixin n-m-2() {
  margin: -0.5rem !important;
}
@mixin n-m-3() {
  margin: -0.75rem !important;
}
@mixin n-m-4() {
  margin: -1rem !important;
}
@mixin n-m-5() {
  margin: -1.25rem !important;
}
@mixin n-m-6() {
  margin: -1.5rem !important;
}
@mixin n-m-8() {
  margin: -2rem !important;
}
@mixin n-m-auto() {
  margin: auto !important;
}
@mixin n-mt-0() {
  margin-top: 0 !important;
}
@mixin n-mt-1() {
  margin-top: -0.25rem !important;
}
@mixin n-mt-2() {
  margin-top: -0.5rem !important;
}
@mixin n-mt-3() {
  margin-top: -0.75rem !important;
}
@mixin n-mt-4() {
  margin-top: -1rem !important;
}
@mixin n-mt-5() {
  margin-top: -1.25rem !important;
}
@mixin n-mt-6() {
  margin-top: -1.5rem !important;
}
@mixin n-mt-8() {
  margin-top: -2rem !important;
}
@mixin n-mt-auto() {
  margin-top: auto !important;
}
@mixin n-mb-0() {
  margin-bottom: 0 !important;
}
@mixin n-mb-1() {
  margin-bottom: -0.25rem !important;
}
@mixin n-mb-2() {
  margin-bottom: -0.5rem !important;
}
@mixin n-mb-3() {
  margin-bottom: -0.75rem !important;
}
@mixin n-mb-4() {
  margin-bottom: -1rem !important;
}
@mixin n-mb-5() {
  margin-bottom: -1.25rem !important;
}
@mixin n-mb-6() {
  margin-bottom: -1.5rem !important;
}
@mixin n-mb-8() {
  margin-bottom: -2rem !important;
}
@mixin n-mb-auto() {
  margin-bottom: auto !important;
}
@mixin n-ml-0() {
  margin-left: 0 !important;
}
@mixin n-ml-1() {
  margin-left: -0.25rem !important;
}
@mixin n-ml-2() {
  margin-left: -0.5rem !important;
}
@mixin n-ml-3() {
  margin-left: -0.75rem !important;
}
@mixin n-ml-4() {
  margin-left: -1rem !important;
}
@mixin n-ml-5() {
  margin-left: -1.25rem !important;
}
@mixin n-ml-6() {
  margin-left: -1.5rem !important;
}
@mixin n-ml-8() {
  margin-left: -2rem !important;
}
@mixin n-ml-auto() {
  margin-left: auto !important;
}
@mixin n-mr-0() {
  margin-right: 0 !important;
}
@mixin n-mr-1() {
  margin-right: -0.25rem !important;
}
@mixin n-mr-2() {
  margin-right: -0.5rem !important;
}
@mixin n-mr-3() {
  margin-right: -0.75rem !important;
}
@mixin n-mr-4() {
  margin-right: -1rem !important;
}
@mixin n-mr-5() {
  margin-right: -1.25rem !important;
}
@mixin n-mr-6() {
  margin-right: -1.5rem !important;
}
@mixin n-mr-8() {
  margin-right: -2rem !important;
}
@mixin n-mr-auto() {
  margin-right: auto !important;
}
@mixin n-px-0() {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
@mixin n-px-1() {
  padding-right: -0.25rem !important;
  padding-left: -0.25rem !important;
}
@mixin n-px-2() {
  padding-right: -0.5rem !important;
  padding-left: -0.5rem !important;
}
@mixin n-px-3() {
  padding-right: -0.75rem !important;
  padding-left: -0.75rem !important;
}
@mixin n-px-4() {
  padding-right: -1rem !important;
  padding-left: -1rem !important;
}
@mixin n-px-5() {
  padding-right: -1.25rem !important;
  padding-left: -1.25rem !important;
}
@mixin n-px-6() {
  padding-right: -1.5rem !important;
  padding-left: -1.5rem !important;
}
@mixin n-px-8() {
  padding-right: -2rem !important;
  padding-left: -2rem !important;
}
@mixin n-px-auto() {
  padding-right: auto !important;
  padding-left: auto !important;
}
@mixin n-py-0() {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
@mixin n-py-1() {
  padding-top: -0.25rem !important;
  padding-bottom: -0.25rem !important;
}
@mixin n-py-2() {
  padding-top: -0.5rem !important;
  padding-bottom: -0.5rem !important;
}
@mixin n-py-3() {
  padding-top: -0.75rem !important;
  padding-bottom: -0.75rem !important;
}
@mixin n-py-4() {
  padding-top: -1rem !important;
  padding-bottom: -1rem !important;
}
@mixin n-py-5() {
  padding-top: -1.25rem !important;
  padding-bottom: -1.25rem !important;
}
@mixin n-py-6() {
  padding-top: -1.5rem !important;
  padding-bottom: -1.5rem !important;
}
@mixin n-py-8() {
  padding-top: -2rem !important;
  padding-bottom: -2rem !important;
}
@mixin n-py-auto() {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
@mixin n-mx-0() {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@mixin n-mx-1() {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
@mixin n-mx-2() {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
@mixin n-mx-3() {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}
@mixin n-mx-4() {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
@mixin n-mx-5() {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}
@mixin n-mx-6() {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
@mixin n-mx-8() {
  margin-right: -2rem !important;
  margin-left: -2rem !important;
}
@mixin n-mx-auto() {
  margin-right: auto !important;
  margin-left: auto !important;
}
@mixin n-my-0() {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
@mixin n-my-1() {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
@mixin n-my-2() {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
@mixin n-my-3() {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
@mixin n-my-4() {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
@mixin n-my-5() {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}
@mixin n-my-6() {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
@mixin n-my-8() {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}
@mixin n-my-auto() {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
@mixin align-baseline() {
  vertical-align: baseline !important;
}
@mixin align-top() {
  vertical-align: top !important;
}
@mixin align-middle() {
  vertical-align: middle !important;
}
@mixin align-bottom() {
  vertical-align: bottom !important;
}
@mixin align-text-bottom() {
  vertical-align: text-bottom !important;
}
@mixin align-text-top() {
  vertical-align: text-top !important;
}
@mixin d-inline() {
  display: inline !important;
}
@mixin d-inline-block() {
  display: inline-block !important;
}
@mixin d-block() {
  display: block !important;
}
@mixin d-grid() {
  display: grid !important;
}
@mixin d-table() {
  display: table !important;
}
@mixin d-table-row() {
  display: table-row !important;
}
@mixin d-table-cell() {
  display: table-cell !important;
}
@mixin d-flex() {
  display: flex !important;
}
@mixin d-inline-flex() {
  display: inline-flex !important;
}
@mixin d-none() {
  display: none !important;
}
@mixin opacity-0() {
  opacity: 0 !important;
}
@mixin opacity-1() {
  opacity: 1 !important;
}
@mixin overflow-auto() {
  overflow: auto !important;
}
@mixin overflow-hidden() {
  overflow: hidden !important;
}
@mixin overflow-visible() {
  overflow: visible !important;
}
@mixin overflow-scroll() {
  overflow: scroll !important;
}
@mixin position-static() {
  position: static !important;
}
@mixin position-relative() {
  position: relative !important;
}
@mixin position-absolute() {
  position: absolute !important;
}
@mixin position-fixed() {
  position: fixed !important;
}
@mixin position-sticky() {
  position: sticky !important;
}
@mixin border-0() {
  border: none !important;
}
@mixin border() {
  border: 1px solid #f1f2f5 !important;
}
@mixin border-top-0() {
  border-top: none !important;
}
@mixin border-top() {
  border-top: 1px solid #f1f2f5 !important;
}
@mixin border-bottom-0() {
  border-bottom: none !important;
}
@mixin border-bottom() {
  border-bottom: 1px solid #f1f2f5 !important;
}
@mixin border-left-0() {
  border-left: none !important;
}
@mixin border-left() {
  border-left: 1px solid #f1f2f5 !important;
}
@mixin border-right-0() {
  border-right: none !important;
}
@mixin border-right() {
  border-right: 1px solid #f1f2f5 !important;
}
@mixin border-blue() {
  border-color: #2593fc !important;
}
@mixin border-blue-1() {
  border-color: #1890ff !important;
}
@mixin border-blue-2() {
  border-color: #b0deff !important;
}
@mixin border-blue-3() {
  border-color: #2dccff !important;
}
@mixin border-teal() {
  border-color: #00bdb1 !important;
}
@mixin border-teal-1() {
  border-color: #a0d9d5 !important;
}
@mixin border-teal-2() {
  border-color: #33afa6 !important;
}
@mixin border-teal-3() {
  border-color: #1d7b77 !important;
}
@mixin border-teal-400() {
  border-color: #2aaeb7 !important;
}
@mixin border-teal-5() {
  border-color: #6cc8bf !important;
}
@mixin border-success() {
  border-color: #33b3aa !important;
}
@mixin border-red() {
  border-color: #ff555b !important;
}
@mixin border-red-1() {
  border-color: #ff3838 !important;
}
@mixin border-red-2() {
  border-color: #f44336 !important;
}
@mixin border-required() {
  border-color: #ff4d4f !important;
}
@mixin border-purple() {
  border-color: #713dff !important;
}
@mixin border-purple-1() {
  border-color: #7b61ff !important;
}
@mixin border-white() {
  border-color: #fff !important;
}
@mixin border-gray() {
  border-color: #9ea7ad !important;
}
@mixin border-gray-1() {
  border-color: #f5f5f5 !important;
}
@mixin border-gray-2() {
  border-color: #d9d9d9 !important;
}
@mixin border-gray-3() {
  border-color: #e3e4e7 !important;
}
@mixin border-gray-100() {
  border-color: #f1f2f5 !important;
}
@mixin border-gray-200() {
  border-color: #ebebeb !important;
}
@mixin border-gray-300() {
  border-color: #d8d8d8 !important;
}
@mixin border-gray-400() {
  border-color: #b2b2b2 !important;
}
@mixin border-gray-500() {
  border-color: #8d8d8f !important;
}
@mixin border-gray-600() {
  border-color: #808080 !important;
}
@mixin border-gray-700() {
  border-color: #3e3e3e !important;
}
@mixin border-gray-800() {
  border-color: #2a2a2a !important;
}
@mixin border-gray-900() {
  border-color: #001529 !important;
}
@mixin border-black() {
  border-color: #000 !important;
}
@mixin border-black-2() {
  border-color: #595959 !important;
}
@mixin border-orange-1() {
  border-color: #f2772e !important;
}
@mixin border-orange-2() {
  border-color: #ff7c7c !important;
}
@mixin border-orange-3() {
  border-color: #ffb302 !important;
}
@mixin border-orange-4() {
  border-color: #ff904d !important;
}
@mixin border-yellow() {
  border-color: #f3b130 !important;
}
@mixin border-yellow-1() {
  border-color: #fdd133 !important;
}
@mixin border-yellow-2() {
  border-color: #fce83a !important;
}
@mixin border-navy-1() {
  border-color: #607eaa !important;
}
@mixin border-pink-1() {
  border-color: #ffc4c4 !important;
}
@mixin border-green() {
  border-color: #56f000 !important;
}
@mixin border-green-1() {
  border-color: #52c41a !important;
}
@mixin border-primary() {
  border-color: #2593fc !important;
}
@mixin border-thin() {
  border-width: 1px !important;
}
@mixin border-thick() {
  border-width: 2px !important;
}
@mixin rounded-sm() {
  border-radius: 3px !important;
}
@mixin rounded() {
  border-radius: 4px !important;
}
@mixin rounded-xl() {
  border-radius: 10px !important;
}
@mixin rounded-lg() {
  border-radius: 17px !important;
}
@mixin rounded-none() {
  border-radius: 0 !important;
}
@mixin rounded-top-sm() {
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
}
@mixin rounded-top() {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
@mixin rounded-top-xl() {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
@mixin rounded-top-lg() {
  border-top-left-radius: 17px !important;
  border-top-right-radius: 17px !important;
}
@mixin rounded-top-none() {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
@mixin rounded-bottom-sm() {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
@mixin rounded-bottom() {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
@mixin rounded-bottom-xl() {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
@mixin rounded-bottom-lg() {
  border-bottom-right-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
}
@mixin rounded-bottom-none() {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
@mixin rounded-left-sm() {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
@mixin rounded-left() {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
@mixin rounded-left-xl() {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
@mixin rounded-left-lg() {
  border-top-left-radius: 17px !important;
  border-bottom-left-radius: 17px !important;
}
@mixin rounded-left-none() {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
@mixin rounded-right-sm() {
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
@mixin rounded-right() {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
@mixin rounded-right-xl() {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
@mixin rounded-right-lg() {
  border-top-right-radius: 17px !important;
  border-bottom-right-radius: 17px !important;
}
@mixin rounded-right-none() {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
@mixin shadow() {
  box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.1) !important;
}
@mixin shadow-light() {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px !important;
}
@mixin w-25() {
  width: 25% !important;
}
@mixin w-50() {
  width: 50% !important;
}
@mixin w-75() {
  width: 75% !important;
}
@mixin w-100() {
  width: 100% !important;
}
@mixin w-auto() {
  width: auto !important;
}
@mixin h-25() {
  height: 25% !important;
}
@mixin h-50() {
  height: 50% !important;
}
@mixin h-75() {
  height: 75% !important;
}
@mixin h-100() {
  height: 100% !important;
}
@mixin h-auto() {
  height: auto !important;
}
@mixin flex-fill() {
  flex: 1 1 auto !important;
}
@mixin flex-row() {
  flex-direction: row !important;
}
@mixin flex-column() {
  flex-direction: column !important;
}
@mixin flex-row-reverse() {
  flex-direction: row-reverse !important;
}
@mixin flex-column-reverse() {
  flex-direction: column-reverse !important;
}
@mixin flex-grow-0() {
  flex-grow: 0 !important;
}
@mixin flex-grow-1() {
  flex-grow: 1 !important;
}
@mixin flex-shrink-0() {
  flex-shrink: 0 !important;
}
@mixin flex-shrink-1() {
  flex-shrink: 1 !important;
}
@mixin flex-wrap-wrap() {
  flex-wrap: wrap !important;
}
@mixin flex-wrap-nowrap() {
  flex-wrap: nowrap !important;
}
@mixin flex-wrap-wrap-reverse() {
  flex-wrap: wrap-reverse !important;
}
@mixin justify-content-start() {
  justify-content: flex-start !important;
}
@mixin justify-content-end() {
  justify-content: flex-end !important;
}
@mixin justify-content-center() {
  justify-content: center !important;
}
@mixin justify-content-between() {
  justify-content: space-between !important;
}
@mixin justify-content-around() {
  justify-content: space-around !important;
}
@mixin justify-content-evenly() {
  justify-content: space-evenly !important;
}
@mixin align-items-start() {
  align-items: flex-start !important;
}
@mixin align-items-end() {
  align-items: flex-end !important;
}
@mixin align-items-center() {
  align-items: center !important;
}
@mixin align-items-baseline() {
  align-items: baseline !important;
}
@mixin align-items-stretch() {
  align-items: stretch !important;
}
@mixin align-content-start() {
  align-content: flex-start !important;
}
@mixin align-content-end() {
  align-content: flex-end !important;
}
@mixin align-content-center() {
  align-content: center !important;
}
@mixin align-content-between() {
  align-content: space-between !important;
}
@mixin align-content-around() {
  align-content: space-around !important;
}
@mixin align-content-stretch() {
  align-content: stretch !important;
}
@mixin align-self-auto() {
  align-self: auto !important;
}
@mixin align-self-start() {
  align-self: flex-start !important;
}
@mixin align-self-end() {
  align-self: flex-end !important;
}
@mixin align-self-center() {
  align-self: center !important;
}
@mixin align-self-baseline() {
  align-self: baseline !important;
}
@mixin align-self-stretch() {
  align-self: stretch !important;
}
@mixin order-0() {
  order: 0 !important;
}
@mixin order-1() {
  order: 1 !important;
}
@mixin order-2() {
  order: 2 !important;
}
@mixin order-3() {
  order: 3 !important;
}
@mixin order-4() {
  order: 4 !important;
}
@mixin order-5() {
  order: 5 !important;
}
@mixin order-first() {
  order: -1 !important;
}
@mixin order-last() {
  order: 6 !important;
}
@mixin text-start() {
  text-align: left !important;
}
@mixin text-end() {
  text-align: right !important;
}
@mixin text-center() {
  text-align: center !important;
}
@mixin text-decoration-none() {
  text-decoration: none !important;
}
@mixin text-decoration-underline() {
  text-decoration: underline !important;
}
@mixin text-transform-lowercase() {
  text-transform: lowercase !important;
}
@mixin text-transform-uppercase() {
  text-transform: uppercase !important;
}
@mixin text-transform-capitalize() {
  text-transform: capitalize !important;
}
@mixin white-space-wrap() {
  white-space: wrap !important;
}
@mixin white-space-nowrap() {
  white-space: nowrap !important;
}
@mixin pe-none() {
  pointer-events: none !important;
}
@mixin pe-auto() {
  pointer-events: auto !important;
}
@mixin visibility-visible() {
  visibility: visible !important;
}
@mixin visibility-hidden() {
  visibility: hidden !important;
}
@mixin float-right() {
  float: right !important;
}
@mixin float-left() {
  float: left !important;
}
@mixin float-none() {
  float: none !important;
}
@mixin cursor-none() {
  cursor: none !important;
}
@mixin cursor-pointer() {
  cursor: pointer !important;
}
@mixin cursor-default() {
  cursor: default !important;
}
@mixin cursor-not-allowed() {
  cursor: not-allowed !important;
}
@mixin font-style-normal() {
  font-style: normal !important;
}
@mixin font-style-italic() {
  font-style: italic !important;
}
@mixin font-style-oblique() {
  font-style: oblique !important;
}
@mixin text-indent-0() {
  text-indent: 0 !important;
}
@mixin text-indent-1() {
  text-indent: 0.25rem !important;
}
@mixin text-indent-2() {
  text-indent: 0.5rem !important;
}
@mixin text-indent-3() {
  text-indent: 0.75rem !important;
}
@mixin text-indent-4() {
  text-indent: 1rem !important;
}
@mixin text-indent-5() {
  text-indent: 1.25rem !important;
}
@mixin text-indent-6() {
  text-indent: 1.5rem !important;
}
@mixin text-indent-8() {
  text-indent: 2rem !important;
}
@mixin text-indent-auto() {
  text-indent: auto !important;
}
@mixin text-xs() {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
@mixin text-xs-medium() {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
@mixin text-xs-bold() {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}
@mixin text-base() {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-base-low() {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
@mixin text-base-medium() {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-base-bold() {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
@mixin text-sm() {
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
}
@mixin text-md() {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
@mixin text-md-regular() {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
@mixin text-md-bold() {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
@mixin text-lg() {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
@mixin text-xl() {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
@mixin text-sxxl() {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}
@mixin text-sxxl-medium() {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}
@mixin text-xxl() {
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
}
@mixin text-xxl-bold() {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
}
@mixin text-xxxl() {
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
}
@mixin text-4xl() {
  font-weight: 500;
  font-size: 44px;
  line-height: 52px;
}
